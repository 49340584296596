import style from "./about.module.css"
import View from "../../items/view/View";
import Text from "../../items/text/Text";
import Input from "../../items/input/Input";

function About() {
    return (
        <View dr="column" className={style.parentAbout}>
            <Text>About Us - Oraniom</Text>
            <Input textArea readOnly noStyle value={`Oraniom is an innovative website that works to provide a comprehensive experience that combines a variety of integrated services. Our current headquarters are in Morocco, and we strive to develop an integrated digital platform that meets the daily needs of users in multiple areas, such as reading, shopping, and communication.

Although Oraniom’s services have not yet been released, they will be gradually launched in the near future, and Oraniom Book will be the first service to be offered. This platform will allow creators to publish and sell their e-books and comics in PDF format, giving them the opportunity to reach a wide audience, while readers will be able to easily discover new works.

How can you support us?
We have great ambitions to gradually launch Oraniom’s services, but to achieve this goal, we need your support! We have launched a campaign on Kickstarter to support the launch of Oraniom Book, and develop the platform to include other future services. Your support will help us accelerate the launch of the rest of our services such as Oraniom Shop and Oraniom Chat, and make our platform available to everyone.

By supporting us on Kickstarter, you will be part of our journey towards building an integrated platform from Morocco that meets your digital needs in one place.

Join us today and help make this dream come true by supporting us on Kickstarter. Together, we create the future!`} />
        </View>
    )
}

export default About;