import View from "../../items/view/View"
import style from "./background.module.css"

function BackGround(){
    return(
        <View className={style.parentVideo}>
        <video className={style.videoBackground} src={require('../../../assets/videos/background.webm')} muted loop autoPlay></video>
        </View>
    )
}

export default BackGround