import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import BackGround from './components/models/background/Background';
import Header from './components/models/header/Header';
import Text from './components/items/text/Text';

function App() {
  function ScrollToTop(){
    const {pathname} = useLocation()
    useEffect(() => {
      window.scrollTo(0,0)
    },[pathname])

    return null;
  }
  return (
    <>
    <BackGround />
    <Text className='centerMessage'>Coming soon</Text>
    </>
    // <BrowserRouter>
    // <Header/>
    // <ScrollToTop />
    // <Routes>
    //   <Route path="/" element={<Home />}/>
    //   <Route path="/about" element={<About />}/>
    // </Routes>
    // </BrowserRouter>
  )
}

export default App;
