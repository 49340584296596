// import style from "./view.module.css"

function View(props) {
    const style = {
      display: "flex",
      gap:props.gap
    };
    /* dr = flexDirection
    props : 
    dr = row / rowR / column / columnR / nullflex
      -row
      -rowR = row-reverse
      -column
      -columnR = column-reverse
      -nullflex = (without display = flex)
      */
  
    function flexDirection() {
      switch(props.dr){
        case 'row': return { flexDirection: "row" };
        case 'rowR': return { flexDirection: "row-reverse" };
        case 'column': return { flexDirection: "column" };
        case 'columnR': return { flexDirection: "column-reverse" };
        default: return {};
      }
    }
    let custom = () => {
      if(!props.noStyle){
        return {...style,...flexDirection()}
      }else{
        return {...flexDirection()}
      }
    }
    return (
      <div id={props.id} style={custom()} className={props.className}>
        {props.children}
      </div>
    );
  }
  
  export default View;
  